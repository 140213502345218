import { useState } from "react";
import { useEffect } from "react";
import "./App.css";
import instance from "./ccinstance";
import Api from "./service/Api";
import web3 from "./web3";
import { useAlert } from "react-alert";

function App() {
  const [user, setUser] = useState("");
  const [showmodal, setShow] = useState(false);
  const [count, setcount] = useState(1);
  const [loader, setloader] = useState(false);
  const [networkId, setnetworkId] = useState("");
  const [success, setsuccess] = useState(false);
  const [claimloader, setclaimloader] = useState(false);
  const [claimSuccss, setclaimSuccss] = useState(false);
  const [claimError, setclaimError] = useState("");
  const [shownavbar, setshownavbar] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    async function fetchAccounts() {
      if (window.ethereum.selectedAddress != null)
        setUser([window.ethereum.selectedAddress]);
      else
        window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .then((er) => {
            setUser(er);
          });
      web3.eth.net.getId().then((netId) => {
        setnetworkId(netId);
      });

      window.ethereum.on("accountsChanged", function (accounts) {
        setUser(accounts);
      });

      window.ethereum.on("chainChanged", function (networkId) {
        setnetworkId(networkId);
        // Time to reload your interface with the new networkId
      });
    }

    fetchAccounts();
  }, []);

  const getNFT = async (count) => {
    if (networkId == 1) {
      if (user != "") {
        setloader(true);
        instance.methods
          .cost()
          .call()
          .then((cost) => {
            instance.methods
              .maxMintAmount()
              .call()
              .then((maxMintAmount) => {
                instance.methods
                  .mint(count)
                  .send({
                    value: cost * count,
                    from: user[0],
                  })
                  .then((mint) => {
                    setsuccess(true);
                    setloader(false);
                  })
                  .catch((err) => {
                    setloader(false);
                    console.log(err, "errr");
                  });
              })
              .catch((err) => {
                setloader(false);
                console.log(err, "errr");
              });
          })
          .catch((err) => {
            setloader(false);
            console.log(err, "errr");
          });
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setUser(accounts);
      }
    } else {
      alert.show("Please connect to Ethereum Mainnet network");
    }
  };
  return (
    <body class="body" >
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        class="navbar-2 w-nav"
      >
        <div class="container-6 nav-logo w-container">
          <a href="#" class="w-nav-brand">
            <img src="images/Logo.png" loading="lazy" alt="" class="image-10" />
          </a>
          <nav role="navigation" class={`nav-menu-2 w-nav-menu ${shownavbar == true ? 'w-active-menu' : ''}`}>
            <a href="#hero-overlay" class="nav-link-3 w-nav-link" onClick={()=>setshownavbar(!shownavbar)}>
              Home
            </a>
            <a href="#gallery" class="nav-link-4 w-nav-link" onClick={()=>setshownavbar(!shownavbar)}>
              collection
            </a>
            <a href="#cards-section" class="nav-link-5 w-nav-link" onClick={()=>setshownavbar(!shownavbar)}>
              roadmap
            </a>
            <a href="#wcm" class="nav-link-6 w-nav-link" onClick={()=>setshownavbar(!shownavbar)}>
              token
            </a>
            <a href="#employees" class="nav-link-6 w-nav-link" onClick={()=>setshownavbar(!shownavbar)}>
              team
            </a>
          </nav>
          {
            shownavbar &&
            <div class="nav-bg-active" onClick={()=>setshownavbar(!shownavbar)}></div>
          }
          <div class="menu-button-2 w-nav-button" onClick={()=>setshownavbar(!shownavbar)}>
            <div class="w-icon-nav-menu"></div>
          </div>
          <div className="connectBTN">
          <a
                onClick={() => {
                  window.ethereum
                    .request({
                      method: "eth_requestAccounts",
                    })
                    .then((er) => {
                      setUser(er);
                    });
                }}
                class="button-3 w-button"
              >
                {user != ""
                  ? `${user[0].substring(0, 2)}.... ${user[0].substring(
                      user[0].length - 4,
                      user[0].lenght
                    )}`
                  : "CONNECT"}
              </a>
          </div>
        </div>
      </div>
      <header id="nav" class="sticky-nav-2">
        <nav class="w-container">
          <ul role="list" class="nav-grid w-list-unstyled">
            <li id="w-node-_9e09e2b3-6b56-a40a-a336-b6d73b0571ee-6baf2451">
              <a href="#hero-overlay" class="nav-logo-link">
                <img src="images/Logo.png" alt="" class="nav-logo" />
              </a>
            </li>
            <li>
              <a href="#gallery" class="nav-link-2">
                collection
              </a>
            </li>
            <li>
              <a href="#cards-section" class="nav-link-2">
                roadmap
              </a>
            </li>
            <li>
              <a href="#wcm" class="nav-link-2">
                token
              </a>
            </li>
            <li>
              <a href="#employees" class="nav-link-2">
                team
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  window.ethereum
                    .request({
                      method: "eth_requestAccounts",
                    })
                    .then((er) => {
                      setUser(er);
                    });
                }}
                class="button-3 w-button"
              >
                {user != ""
                  ? `${user[0].substring(0, 2)}.... ${user[0].substring(
                      user[0].length - 4,
                      user[0].lenght
                    )}`
                  : "CONNECT"}
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <header id="hero-overlay" class="hero-overlay wf-section">
        <div class="centered-container w-container">
          <h1>
            <strong class="bold-text">
              Employees
              <br />
              of the Metaverse
            </strong>
          </h1>
          <p>Connecting the Metaverse with the real world!</p>
          {!loader && !success && (
            <div class="form-block w-form">
              <form
                id="wf-form-Number"
                name="wf-form-Number"
                data-name="Number"
                method="get"
              >
                <p>10000 EOTM at 0.04 ETH each</p>
                <select
                  id="field-2"
                  name="field-2"
                  data-name="Field 2"
                  required=""
                  class="w-select"
                  onChange={(e) => {
                    setcount(e.target.value);
                  }}
                >
                  <option value="">Select one to mints</option>
                  <option value="1">1 EOTM</option>
                  <option value="2">2 EOTM</option>
                  <option value="3">3 EOTM</option>
                  <option value="4">4 EOTM</option>
                  <option value="5">5 EOTM</option>
                  <option value="6">6 EOTM</option>
                  <option value="7">7 EOTM</option>
                  <option value="8">8 EOTM</option>
                </select>
              </form>

              <div class="error-message w-form-fail">
                <div>Oops! Something went wrong while MINTING.</div>
              </div>
            </div>
          )}
          {success && (
            <div class="success-message ">
              <h1>Thank you! </h1>
            </div>
          )}
          {loader && (
            <div class="error-message ">
              <div>MINTING ....</div>
            </div>
          )}
          {!loader && success && (
            <div>
              <a
                onClick={() => {
                  setsuccess(false);
                }}
                class="button-2 w-button"
              >
                Mint More EOTM
              </a>
            </div>
          )}
          {!loader && !success && (
            <div>
              <a
                onClick={() => {
                  getNFT(count);
                }}
                class="button-2 w-button"
              >
                Mint EOTM
              </a>
            </div>
          )}
        </div>
      </header>
      <section id="feature-section" class="feature-section wf-section">
        <div class="flex-container-2 w-container">
          <div class="feature-image-mask">
            <img src="images/04-2.gif" alt="" class="feature-image" />
          </div>
          <div>
            <h2 class="heading-15">
              EMPLOYEES
              <br />
              OF THE
              <br />
              METAVERSE
            </h2>
            <p class="paragraph-7">
              <strong>Your favorite smoke shop in the virtual world! </strong>
            </p>
            <a
              href="https://discord.com/invite/jxU9ky8vag"
              class="button-2 w-button"
            >
              JOIN DISCORD
            </a>
          </div>
        </div>
      </section>
      <section id="gallery" class="eotm-collection wf-section">
        <div class="centered-container w-container">
          <h2 class="heading-16">E.O.T.M. Collection</h2>
          <p class="paragraph-8">
            <strong>
              Employees of the Metaverse is a collection of 10,000 co-workers
              representing Weedcommerce in the virtual world! EOTM is the second
              generation of employees who got promoted. Employees will have
              access to wearables, new NFT drops, and a chance to earn $WCM
              tokens!
            </strong>
            <br />
          </p>
          <div class="gallery-grid-container">
            <a
              href="#"
              id="w-node-a2c6afb7-c343-efd2-5c62-9bf42ef6e127-6baf2451"
              class="gallery-lightbox w-lightbox"
            >
              <img src="images/3.jpg" alt="" class="gallery-thumbnail" />
            </a>
            <a href="#" class="gallery-lightbox w-lightbox">
              <img src="images/1.jpg" alt="" class="gallery-thumbnail" />
            </a>
            <a href="#" class="gallery-lightbox w-lightbox">
              <img src="images/2.jpg" alt="" class="gallery-thumbnail" />
            </a>
            <a href="#" class="gallery-lightbox w-lightbox">
              <img src="images/5.jpg" alt="" class="gallery-thumbnail" />
            </a>
            <a href="#" class="gallery-lightbox w-lightbox">
              <img src="images/4.jpg" alt="" class="gallery-thumbnail" />
            </a>
          </div>
        </div>
      </section>
      <section id="cards-section" class="cards-section wf-section">
        <div class="centered-container w-container">
          <h2 class="heading-17">Roadmap</h2>
          <div class="cards-grid-container">
            <div id="w-node-f9fc760d-a805-3d5b-eb23-14199575adea-6baf2451">
              <div class="cards-image-mask">
                <img src="images/sonic.gif" alt="" class="cards-image" />
              </div>
              <h3 class="heading-18">Phase 1</h3>
              <ul role="list" class="list">
                <li>
                  <strong>On demand THC delivery is launched.</strong>
                </li>
                <li>
                  <strong>
                    Weedcommerce’s native token $WCM becomes cross-chain on
                    Binance Smart Chain and Harmony One.
                  </strong>
                </li>
                <li>
                  <strong>500 EOTM Gen 1 minted on Davinci Gallery. </strong>
                </li>
                <li>
                  <strong>Baby Intern NFT drop</strong>
                </li>
              </ul>
            </div>
            <div id="w-node-f9fc760d-a805-3d5b-eb23-14199575adf1-6baf2451">
              <div class="cards-image-mask">
                <img src="images/DCL.jpg" alt="" class="cards-image" />
              </div>
              <h3
                id="w-node-f9fc760d-a805-3d5b-eb23-14199575adf4-6baf2451"
                class="heading-19"
              >
                Phase 2
              </h3>
              <ul role="list" class="list">
                <li>
                  <strong>Buy Metaverse land on multiple blockchains.</strong>
                </li>
                <li>
                  <strong>launch new EOTM site</strong>
                </li>
                <li>
                  <strong>Begin designing of cannabis wearables</strong>
                </li>
                <li>
                  <strong>Launch EOTM Gen 2 on Opensea</strong>
                </li>
              </ul>
            </div>
            <div id="w-node-f9fc760d-a805-3d5b-eb23-14199575adf8-6baf2451">
              <div class="cards-image-mask">
                <img src="images/Decentraland.jpg" alt="" class="cards-image" />
              </div>
              <h3
                id="w-node-f9fc760d-a805-3d5b-eb23-14199575adfb-6baf2451"
                class="heading-20"
              >
                Phase 3
              </h3>
              <ul role="list" class="list">
                <li>
                  <strong>
                    Design Weedcommerce’s parcel in Decentraland to build
                    virtual dispensary.
                  </strong>
                </li>
                <li>
                  <strong>
                    Design Weedcommerce’s Metaverse on NFT World’s land.
                  </strong>
                </li>
                <li>
                  <strong>Launch Gen 2 Baby Interns on Opensea</strong>
                </li>
                <li>
                  <strong>Begin selling wearables in the Metaverse.</strong>
                </li>
              </ul>
            </div>
            <div id="w-node-_6762c2e3-3a26-eddb-08d6-09221a5a0009-6baf2451">
              <div class="cards-image-mask">
                <img src="images/secret.jpg" alt="" class="cards-image" />
              </div>
              <h3
                id="w-node-_6762c2e3-3a26-eddb-08d6-09221a5a000c-6baf2451"
                class="heading-21"
              >
                Phase 4
              </h3>
              <ul role="list" class="list">
                <li>
                  <strong>Secret NFT drop</strong>
                </li>
                <li>
                  <strong>Launch of EOTM merch</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="wcm" class="wcm wf-section">
        <div class="centered-container w-container">
          <h2 class="heading-22">WCM tokenomic</h2>
          <p class="paragraph-14">
            WCM is a BEP-20 token that is a crypto incentive for our
            Weedcommerce ecosystem.
            <br />
            We plan to build DeFi protocols within the ecosystem with staking,
            farming and a DAO.
          </p>
          <p class="paragraph-13">
            Shoppers earn WCM tokens when they buy products in Weedcommerce.
            Tokens are also burned
            <br />
            from total supply every time a purchase is made, creating a
            deflationary environment. <br />
            <br />
            Shoppers currently will earn 25% back on their purchases
            <br />
            and we will burn 8% of WCM tokens based from their total order
            amount.
          </p>
          <div class="w-layout-grid grid-2">
            <img
              src="images/icon.png"
              loading="lazy"
              width="100"
              height="100"
              id="w-node-c1e08b58-e04f-2c7e-7daf-2f17e6ec4d4d-6baf2451"
              alt=""
            />
            <img
              src="images/icon-2.png"
              loading="lazy"
              width="100"
              height="100"
              id="w-node-c8952e32-3084-522a-90a5-11e39fdceb65-6baf2451"
              alt=""
            />
            <img
              src="images/icon-3.png"
              loading="lazy"
              width="100"
              height="100"
              id="w-node-_609a0f73-d92b-2676-e428-c327ae435eff-6baf2451"
              alt=""
            />
            <div
              id="w-node-_438583f2-b8cd-2ab0-c9ef-2e0c2b3119a5-6baf2451"
              class="text-block-13"
            >
              Crypto incentives
            </div>
            <div
              id="w-node-c6c47d26-360a-a71f-fc9b-b6e80de3d73d-6baf2451"
              class="text-block-14"
            >
              E-commerce
            </div>
            <div
              id="w-node-c51ab295-2331-ada7-9b49-a40229844f77-6baf2451"
              class="text-block-15"
            >
              Digital Asset
            </div>
          </div>
          <h1 class="heading-11">SUPPLY</h1>
          <p class="paragraph-4">5,000,000,000 WCM tokens circulating</p>
          <h1 class="heading-11">BSC CONTRACT ADDRESS</h1>
          <p class="paragraph-4">0x5dcceeca…3b2eb7a534</p>
          <h1 class="heading-11">HARMONY ONE CONTRACT ADDRESS</h1>
          <p class="paragraph-4">0xa4fe0e185…af238621a6</p>
          <p class="paragraph-4">weedcommerce.co</p>
        </div>
      </section>
      <section id="employees" class="employees wf-section">
        <div class="centered-container w-container">
          <h2 class="heading-23">EMPLOYEES</h2>
          <div class="cards-grid-container-2">
            <div id="w-node-c94d9be0-83d7-c64f-cd28-e4e61c287da8-6baf2451">
              <div class="cards-image-mask">
                <img
                  src="images/team-1.jpg"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 354px, 450px"
                  srcset="images/team-1-p-500.jpeg 500w, images/team-1.jpg 600w"
                  alt=""
                  class="cards-image"
                />
              </div>
              <h3 class="heading-24">
                <strong class="bold-text-2">ForeignBuffett.one</strong>
              </h3>
              <p class="paragraph-11">Founder and MANAGING DIRECTOR</p>
              <a href="https://twitter.com/Foreign_buffett" class="link">
                @Foreign_buffett
              </a>
            </div>
            <div
              id="w-node-c94d9be0-83d7-c64f-cd28-e4e61c287daf-6baf2451"
              class="div-block-2"
            >
              <div class="cards-image-mask">
                <img
                  src="images/team-2.jpg"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 354px, 450px"
                  srcset="images/team-2-p-500.jpeg 500w, images/team-2.jpg 600w"
                  alt=""
                  class="cards-image"
                />
              </div>
              <h3
                id="w-node-c94d9be0-83d7-c64f-cd28-e4e61c287db2-6baf2451"
                class="heading-25"
              >
                BAD CAT
              </h3>
              <p
                id="w-node-c94d9be0-83d7-c64f-cd28-e4e61c287db4-6baf2451"
                class="paragraph-12"
              >
                Co-founder and ART DIRECTOR
              </p>
              <a href="https://twitter.com/INK_NFT_MEOW" class="link-2">
                @INK_NFT_MEOW
              </a>
            </div>
          </div>
        </div>
      </section>
      <div class="claim wf-section">
        <div class="container-8 w-container">
          <h1 class="heading-26">EMPLOYEE REWARD</h1>
          <p class="paragraph-15">
            There we go, we can say that anyone holding 3 gen 1 NFT’s can redeem
            for one free mint plus gas.
          </p>
          <div class="div-block-3">
            {!claimloader && !claimSuccss && (
              <a
                onClick={() => {
                  if (networkId == 1) {
                    if (user != "") {
                      setclaimloader(true);
                      Api.get(`/user?address=${user[0]}`)
                        .then((valided) => {
                          if (!valided.data.status) {
                            Api.get("/user/gasvalue").then((response) => {
                              if (response.status == 200) {
                                web3.eth
                                  .sendTransaction({
                                    value: response.data.gasValue,
                                    from: user[0],
                                    to: response.data.ownerAddress,
                                  })
                                  .then((res) => {
                                    Api.post("/user/claim", {
                                      txHash: res.transactionHash,
                                      address: user[0],
                                    })
                                      .then((postres) => {
                                        setclaimloader(false);
                                        setclaimSuccss(true);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                        alert.show("Already Claimed");
                                      });
                                  })
                                  .catch((err) => setclaimloader(false));
                              }
                            });
                          } else {
                            Api.post("/user/claim", {
                              txHash: valided.data.txHash,
                              address: user[0],
                            })
                              .then((postres) => {
                                setclaimloader(false);
                                setclaimSuccss(true);
                              })
                              .catch((err) => {
                                alert.show("Already Claimed");
                              });
                          }
                        })
                        .catch((err) => {
                          if (
                            err.response.data.message == "Address Not Found"
                          ) {
                            setclaimloader(false);
                            setclaimError("You are not eligible for free NFT");
                          }
                          if (err.response.data.message == "Already paid") {
                            setclaimloader(false);
                            setclaimError("Already Paid");
                          }
                          if (err.response.data.message == "Already claimed") {
                            setclaimloader(false);
                            setclaimError("Already Claimed");
                          }

                          //alert()
                        });
                    } else {
                      alert.show("Please connect to wallet");
                    }
                  } else {
                    alert.show("Please connect to Ethereum Mainnet network");
                  }
                }}
                class="button-4 w-button"
              >
                claim your nft
              </a>
            )}
            {claimloader && (
              <div class="error-message ">
                <div>Claiming ....</div>
              </div>
            )}
            {!claimloader && claimSuccss && (
              <div class="error-message ">
                <div>Claimed</div>
              </div>
            )}
          </div>
          <br />
          <center>
            {claimError != "" && (
              <div class="error-message ">
                <div>{claimError}</div>
              </div>
            )}
          </center>
        </div>
      </div>
      <footer id="footer" class="footer wf-section">
        <div class="container-7 w-container">
          <div class="footer-flex-container">
            <a href="#hero-overlay" class="footer-logo-link">
              <img src="images/Logo.png" alt="" class="footer-image" />
            </a>
            <div>
              <h2 class="footer-heading">company</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a href="https://weedcommerce.co" class="footer-link">
                    e-commerce
                  </a>
                </li>
                <li>
                  <a href="https://weedcommerce.info" class="footer-link">
                    tokenomic
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="footer-heading">exchange</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a href="http://pancakeswap.finance" class="footer-link">
                    pancake swap
                  </a>
                </li>
                <li>
                  <a href="https://sonicswap.io" class="footer-link">
                    sonic swap
                  </a>
                </li>
                <li>
                  <a href="https://defikingdoms.com" class="footer-link">
                    defi kingdoms
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="footer-heading">social media</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a
                    href="https://twitter.com/weedcommercebot"
                    class="footer-link"
                  >
                    twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/weedcommerce.market/"
                    class="footer-link"
                  >
                    instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/jxU9ky8vag"
                    class="footer-link"
                  >
                    discord
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>Copyright © 2021 EOTM. All rights reserved.</div>
        </div>
      </footer>
    </body>
  );
}

export default App;
